<template>
<div class="form-authorization-flex">
  <div class="form-authorization">
      <div class="flex justify-content-center">
          <div class="card">
            <div style="display: flex;justify-content: space-between;height: 34px;">
                <h3 style="align-self: center;" class="text-center h3-label">{{gClick?'Регистрация':'Вход'}}</h3>
                <Button :label="`${gClick?'Вход':'Регистрация'}`" @click="generalBtn()" class="mt-2" />
            </div>
            <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <div class="field">
                    <div class="p-float-label p-input-icon-right">
                        <i class="pi pi-envelope" />
                        <InputText :disabled="loading" id="email" type="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                        <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
                    </div>
                    <span v-if="v$.email.$error && submitted">
                        <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                        <small class="p-error">{{error.$message}}</small>
                        </span>
                    </span>
                    <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value is required', 'Введите Email')}}</small>
                </div>
                <div class="field">
                    <div class="p-float-label">
                        <Password :disabled="loading" id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask :feedback="false"/>
                        <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Password*</label>
                    </div>
                    <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value is required', 'Введите пароль')}}</small>
                </div>
                <Button :loading="loading" type="submit" :label="`${gClick?'Регистрация':'Вход'}`" class="mt-2" />
            </form>
          </div>
      </div>
  </div>
</div>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import store from '@/store';
import router from '@/router';

export default {
    name: 'AthorizationView',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            gClick: false,
            location: '',
            email: '',
            password: '',
            submitted: false            
        }
    },
    validations() {
        return {
            email: {
                required,
                email
            },
            password: {
                required
            },
        }
    },
    created() {
        if(document.location.hostname=='localhost') {
            this.location = 'https://ruproger.ru/'
        }

        function getCookie(name) {
            var matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }
        if(getCookie('login'))
            this.email = getCookie('login')
        if(getCookie('password'))
            this.password = getCookie('password')
    },
    mounted() {
        
    },
    methods: {
        generalBtn () {
            this.gClick = !this.gClick
            this.resetForm()
        },
        handleSubmit(isFormValid) {
            this.submitted = true;
  
            if (!isFormValid) {
                return;
            }

            this.logined();
        },

        resetForm() {
            this.email = '';
            this.password = '';
            this.submitted = false;
        },

        logined() {
            this.loading = true

            if ( !this.gClick ) {
                axios
                .post(`${this.location}assets/logined.php`, {
                    login: this.email,
                    password: this.password,
                    type: 'login_validate'
                })
                .then(response => {
                    if( response.data.obj.logined && response.data.obj.activity === 1 ) {
                        store.state.logined = true
                        store.state.id = response.data.obj.id
                        store.state.privileges = response.data.obj.privileges
                        store.state.crm_id = response.data.obj.crm_id
                        store.state.photo = response.data.obj.photo,
                        store.state.name = response.data.obj.name

                        document.cookie = `login=${this.email}; path=/;max-age=31556926`
                        document.cookie = `password=${this.password}; path=/;max-age=31556926`                    
                        
                        this.resetForm()
                        router.push('/table') 

                        this.loading = false
                    } else {
                        if ( response.data.obj.activity === 0 )
                            this.$toast.add({severity:'info', summary: 'Уведомление', detail:`Учетная запись не активирована`, life: 7000, group: 'top-right'});
                        else
                            this.$toast.add({severity:'error', summary: 'Уведомление', detail:`Проверьте логин и пароль`, life: 2000, group: 'top-right'});

                        this.loading = false
                    }
                })
                .catch(error => {
                    this.$toast.add({severity:'error', summary: 'Уведомление', detail:`Ошибка: ${error.message} ${error.code}`, life: 2000, group: 'top-right'});

                    this.loading = false
                })
            } else {
                axios
                .post(`${this.location}assets/logined.php`, {
                    login: this.email,
                    password: this.password,
                    type: 'registration'
                })
                .then(response => {
                    if ( response.data.obj ) {

                        this.$toast.add({severity:'info', summary: 'Уведомление', detail:`Регистрация прошла успешно`, life: 4000, group: 'top-right'});
                        this.resetForm()  
                    }  else {

                        this.$toast.add({severity:'info', summary: 'Уведомление', detail:`Email занят`, life: 5000, group: 'top-right'});
                    }                    

                    this.loading = false
                })
                .catch(error => {
                    this.$toast.add({severity:'error', summary: 'Уведомление', detail:`Ошибка: ${error.message} ${error.code}`, life: 2000, group: 'top-right'});

                    this.loading = false
                })
            }
        },
    }
}
</script>

<style lang="scss">
#password i {
    cursor: pointer;
}
.h3-label {
    text-align: center;
}
.form-authorization-flex {
  height: 100%;
  display: flex;
}
.form-authorization {
  max-width: 450px;
  margin: auto;
  width: 100%;

  .card {
      width: 100%;
      margin: auto;  

      form {
          margin-top: 2rem;            
      }

      .field {
          margin-bottom: 1.5rem;
      }
  }

  @media screen and (max-width: 960px) {
      .card {
          width: 80%;
      }
  }
}
</style>
