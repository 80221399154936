<template>
    <router-view/>
    <Toast position="top-left" group="top-left" />
    <Toast position="top-center" group="top-center" />
    <Toast position="top-right" group="top-right" />
    <Toast position="center" group="center" />
    <Toast position="bottom-left" group="bottom-left" />
    <Toast position="bottom-center" group="bottom-center" />
    <Toast position="bottom-right" group="bottom-right" />
</template>

<script>
export default {
  name: 'View',
  data() {
      return {
          location: ''
      }
  },

  created() {
    if ( document.location.hostname == 'localhost' ) {
        this.location = 'https://ruproger.ru/'
    }

    function getCookie(name) {
      var matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    if(getCookie('theme'))
        this.theme() 
  },
  mounted() { 
      
  },
  methods: {
    theme () {
      var checkTheme = $('body').find('#theme-link')
      var checkStyleTheme = $('body').find('#style-theme')
      if(checkTheme.length) {
        document.cookie = `theme=false; path=/;max-age=0`
        checkTheme.remove()
        checkStyleTheme.remove()
      } else {
        document.cookie = `theme=true; path=/;max-age=31556926`  
        $('body').append(`<link id="theme-link" rel="stylesheet" href="${this.location}themes/bootstrap4-dark-blue/theme.css">`);
        $('body').append(`
            <style id="style-theme">
                .p-datatable-sm tr:hover {background: #323a45;}
                .a-link-tiket, .a-link-tiket-left-slidebar {color: rgba(255, 255, 255, 0.87);}
                body {background: #2a323d;}
                body * {font-weight: normal!important;}
                .color-subcategory {color: rgba(255, 255, 255, 0.87);}
                .settings .head-settings {background: #2a323d!important;border:1px solid #3f4b5b;!important;}
                .settings .info-settings-employee {background: #2a323d!important;border:1px solid #3f4b5b;!important;}
                .settings .logo-settings-employee {background: #2a323d!important;border:1px solid #3f4b5b;!important;}
                .settings .body-settings {background: #2a323d!important;border:1px solid #3f4b5b;!important;}
                .p-datatable-tbody.p-virtualscroller-content {top: 36px !important;}
            </style>
        `);
      }            
    }
  }        
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

*::before, [class*="-icon"] {
  font-family: 'primeicons'!important;
}

html * {
  font-family: 'Montserrat', sans-serif!important;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0px;
  font-size: 14px;
  overflow: hidden;
}

#app {
  height: 100%;
}

.p-toast .p-toast-message.p-toast-message-info {
  border: none!important;
}
</style>