import store from '@/store';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import AthorizationView from '../views/AthorizationView.vue'

/*const СheckLogined = (to, from, next) => {
    if(store.state.logined)
        next(from.path)
    else
        next()
}*/    

const Logined = (to, from, next) => {
    var location = ''
    if(document.location.hostname=='localhost') {
        location = 'https://ruproger.ru/'
    }
    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const login = getCookie('login')
    const password = getCookie('password')
    if(!store.state.logined && login && password) {
        axios
        .post(`${location}assets/logined.php`, {
            login: login,
            password: password,
            type: 'login_validate'
        })
        .then(response => {   
            if( response.data.obj.logined && response.data.obj.activity === 1 ) {
                store.state.logined = true
                store.state.id = response.data.obj.id
                store.state.privileges = response.data.obj.privileges
                store.state.crm_id = response.data.obj.crm_id
                store.state.photo = response.data.obj.photo,
                store.state.name = response.data.obj.name

                next()
            } else {
                document.cookie = `login=null; path=/;max-age=0`
                document.cookie = `password=null; path=/;max-age=0`

                next('/')
            }
        })
        .catch(error => {
            console.log(error)

            document.cookie = `login=null; path=/;max-age=0`
            document.cookie = `password=null; path=/;max-age=0`

            next('/')
        })
    } else {
        if(store.state.logined)
            next()
        else
            next('/')
    }
}

const routes = [
  {
    path: '/',
    name: 'authorization',
    component: AthorizationView
    //beforeEnter: СheckLogined
  },
  {
    path: '/table',
    name: 'table',
    // route level code-splitting
    // this generates a separate chunk (table.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "table" */ '../views/TableView.vue'),
    beforeEnter: Logined
  },
  {
    path: '/settings',
    name: 'settings',
    // route level code-splitting
    // this generates a separate chunk (settings.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
    beforeEnter: Logined
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
